<template>
  <v-dialog persistent v-model="dialog" max-width="920px">
    <template v-slot:activator="{ on }">
      <v-btn
        small
        tile
        :elevation="0"
        color="gray"
        style="border-radius: 0 1.5em 1.5em 0"
        @click="openDialog()"
        v-if="check([{ domain: 'Document', permisions: ['Write'] }])"
      >
        <v-icon small color="neuter" left>mdi-plus</v-icon>
        <span class="neuter--text">{{ $t("general.buttons.new") }}</span>
      </v-btn>
    </template>
    <v-card :light="true">
      <v-overlay :value="loadings.init" absolute color="white" opacity=".9">
        <v-progress-circular color="primary" indeterminate size="64" />
      </v-overlay>
      <v-card-title>
        <v-row class="d-flex justify-space-between align-center ma-0 pa-0">
          <v-col cols="6">
            <span class="headline">{{ formTitle }}</span>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-switch
              inset
              class="ma-0 pa-0 mx-2"
              :label="$t('documents.fields.public')"
              v-model="documentSelected.isPublic"
              color="primary"
              hide-details
            />
            <v-switch
              inset
              class="ma-0 pa-0 mx-2"
              :label="$t('general.fields.active')"
              v-model="documentSelected.active"
              color="primary"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="6" class="pr-6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      small
                      clearable
                      item-color="primary"
                      color="primary"
                      return-object
                      item-value="id"
                      item-text="name"
                      :items="documentAuthorities"
                      v-model="documentSelected.documentAuthority"
                      small-chips
                      class="required"
                      outlined
                      dense
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields.authority") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      class="required"
                      :rules="requireRules"
                      clearable
                      small
                      return-object
                      item-color="primary"
                      color="primary"
                      item-value="id"
                      item-text="document_type_name"
                      :items="documentTypes"
                      outlined
                      dense
                      @click:clear="clearDocumentType"
                      @change="
                        documentDescriptionFiltered(
                          documentSelected.documentType
                        )
                      "
                      v-model="documentSelected.documentType"
                      small-chips
                    >
                      >
                      <template v-slot:label>
                        {{ $t("documents.fields.type") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                          {{ item.document_type_name | truncate(50) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item, attrs }">
                        <div style="max-width: 300px">
                          <read-more
                            :classes="'mb-0'"
                            :text="item.document_type_name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      :loading="loadings.description"
                      class="required"
                      :rules="requireRules"
                      return-object
                      clearable
                      :disabled="!documentSelected.documentType"
                      item-color="primary"
                      color="primary"
                      item-value="id"
                      item-text="document_description_name"
                      outlined
                      dense
                      :items="documentDescriptions"
                      v-model="documentSelected.documentDescription"
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields.description") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                          {{ item.document_description_name | truncate(50) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item, attrs }">
                        <div style="max-width: 300px">
                          <read-more
                            :classes="'mb-0'"
                            :text="item.document_description_name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-datetime-picker
                      :show-date="true"
                      :show-time="false"
                      :required="true"
                      :locale="$i18n.locale"
                      :text-field-props="textProps"
                      :label="$t('documents.fields.document_date')"
                      color="primary"
                      v-model="computedDocumentDate"
                      date-format="dd / MM / yyyy"
                      ref="document_dateRef"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-datetime-picker
                      :show-date="true"
                      :show-time="false"
                      :required="false"
                      :locale="$i18n.locale"
                      :text-field-props="textProps"
                      :label="$t('documents.fields.expiration_date')"
                      color="primary"
                      v-model="computedExpirationDate"
                      date-format="dd / MM / yyyy"
                      ref="expiration_dateRef"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      class="required"
                      :rules="requireRules"
                      small
                      clearable
                      item-color="primary"
                      color="primary"
                      item-text="storage_type_name"
                      item-value="id"
                      return-object
                      :items="storageDeviceTypesActiveByCompany"
                      v-model="documentSelected.storageDeviceType"
                      small-chips
                      outlined
                      dense
                      @change="changeSDT({
                        storageDeviceTypeIds: documentSelected.storageDeviceType,
                        isActive: true
                      })"
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields_add.storage_device_type") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                          {{ item.storage_type_name | truncate(50) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item, attrs }">
                        <div style="max-width: 300px">
                          <read-more
                            :classes="'mb-0'"
                            :text="item.storage_type_name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      :clearable="true"
                      color="primary"
                      item-color="primary"
                      :items="locations"
                      :rules="requireRules"
                      class="required"
                      item-text="name"
                      item-value="id"
                      return-object
                      v-model="documentSelected.primaryLocation"
                      :disabled="!documentSelected.storageDeviceType"
                      outlined
                      dense
                      @change="
                        fetchStorages({
                        primaryLocationVersionIds: [documentSelected.primaryLocation],
                        isActive: true
                      })"
                      @update:search-input="
                        fetchLocations({
                        storageDeviceTypeIds: documentSelected.storageDeviceType,
                        isActive: true,
                        stringFilter: $event || ''
                      })"
                      small-chips
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields_add.locations") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                          {{ item.name | truncate(50) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item, attrs }">
                        <div style="max-width: 300px">
                          <read-more
                            :classes="'mb-0'"
                            :text="item.name"
                            :max-chars="80"
                            :font-size="14"
                          />
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      required
                      class="required"
                      color="primary"
                      multiple
                      clearable
                      item-color="primary"
                      item-value="id"
                      item-text="control_label"
                      outlined
                      dense
                      :items="storages"
                      v-model="storagesSelected"
                      type="object"
                      @update:search-input="
                        fetchStorages({
                        primaryLocationVersionIds: [documentSelected.primaryLocation],
                        isActive: true,
                        stringFilter: $event || ''
                      })"
                      small-chips
                      :disabled="!documentSelected.primaryLocation"
                      :rules="selectRule"
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields_add.storage_device") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip small v-if="index === 0">
                          <span>{{ item.control_label | truncate(50) }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption"
                          >(+{{
                            storagesSelected.length - 1
                          }}
                          {{ $t("general.titles.others") }})</span
                        >
                      </template>
                      <template v-slot:item="{ item, attrs }">
                        <div style="max-width: 300px">
                          <v-checkbox
                            :input-value="attrs.inputValue"
                            color="primary"
                            class="pt-2 pb-2 mt-0"
                            :hide-details="true"
                          >
                            <template v-slot:label>
                              <read-more
                                :classes="'mb-0'"
                                :text="item.control_label"
                                :max-chars="80"
                                :font-size="14"
                              />
                            </template>
                          </v-checkbox>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      ref="seasonFilters"
                      :label="$t('records.fields.season')"
                      clearable
                      item-color="primary"
                      item-text="season_name"
                      return-object
                      :items="orderData(seasons, 'season_name')"
                      v-model="season"
                      color="primary"
                      outlined
                      dense
                      @update:search-input="searchSeason($event)"
                      :loading="loadings.seasons"
                    >
                      <template v-slot:prepend-item>
                        <v-alert
                          color="primary"
                          type="info"
                          dense
                          border="right"
                          text
                          tile
                          class="ma-0"
                        >
                          <template #append>
                            <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                          </template>
                          <template #prepend>
                            <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                          </template>
                          {{ $t('general.fields.startTyping') }}
                        </v-alert>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                          <span>{{ item.season_name }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      required
                      class="required"
                      :rules="requireRulesAndSize(fileSelected)"
                      show-size
                      :loading="loadings.file"
                      v-model="fileSelected"
                      color="primary"
                      prepend-icon=""
                      prepend-inner-icon="mdi-camera"
                      small-chips
                      outlined
                      dense
                      @change="onChangeFile()"
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields_add.file") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  onkeypress="return (event.charCode != 34)"
                  v-on:keyup.86="replaceDescriptionText"
                  v-on:keyup.ctrl.86="replaceDescriptionText"
                  v-model="documentSelected.observations"
                  color="primary"
                  :rules="lengthRules(250).concat(limitEnterKeyPress)"
                  :auto-grow="true"
                  :clearable="true"
                  :label="$t('documents.fields.observations')"
                  :no-resize="true"
                  :row-height="40"
                  :rows="3"
                  :counter="250"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" class="d-flex align-center"
                     :class="[{'justify-space-between' : documentSelected.id > 0}, {'justify-start' : !(documentSelected.id > 0)}]"
              >
                <v-switch
                  inset
                  class="mr-2"
                  hide-details
                  :label="$t('documents.fields.enableUseSeason')"
                  v-model="useSeason"
                  color="primary"
                  dense
                ></v-switch>
                <v-switch
                  inset
                  class="mx-2"
                  hide-details
                  :label="$t('documents.fields_add.externalVisibility')"
                  v-model="externalVisibility"
                  color="primary"
                >
                </v-switch>
                <v-switch
                  inset
                  class="ml-2"
                  hide-details
                  v-if="documentSelected.id > 0"
                  :label="$t('documents.fields.renew')"
                  v-model="renew"
                  color="primary"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small text color="neuter" @click="close" :disabled="loadings.save">
          {{ $t("general.buttons.cancel") }}
        </v-btn>
        <v-btn
          small
          elevation="0"
          color="primary"
          class="t-bw-primary--text"
          :loading="loadings.save"
          :disabled="!valid || !selectedlengthStorages || loadings.save"
          @click="save"
        >
          {{ $t("general.buttons.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import PermisionService from "@/services/PermisionService"
  import { mapActions, mapGetters } from "vuex"
  import moment from "moment/moment"
  import CompactImages from "@/services/CompactImages"
  import ReadMore from "@/components/ReadMoreComponent"
  import i18n from "@/plugins/i18n"
  import DocumentsService from '@/services/DocumentsService'
  import LanguageService from '@/services/LanguajeService'
  import LanguajeService from "@/services/LanguajeService";
  import sortList from "@/services/OrderListService";
  import jsZip from "jszip";

  const MIME_TYPE = {
    jpeg: 'image/jpeg',
    jpg: 'image/jpg',
    bmp: 'image/bmp',
    png: 'image/png',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    csv: 'text/csv',
    pdf: 'application/pdf',
    zip: 'application/zip',
    xml: 'text/xml',
  }

  export default {
    name: "AddOrEditComponent",
    components: { ReadMore },
    props: {
      profile: {
        type: Object,
        require: true
      },
      dialog: {
        type: Boolean,
        require: true,
        default: false
      }
    },

    data: () => ({
      valid: false,
      loadings: {
        init: false,
        file: false,
        description: false,
        save: false,
        origin: false,
        destination: false,
        document: false,
        seasons: false
      },
      editedIndex: -1,
      season: null,
      useSeason: false,
      observations_text: "",
      location: null,
      storagesSelected: [],
      renew: false,
      fileEditChange: false,
      fileSelected: null,
      textProps: {
        appendIcon: "mdi-calendar",
        color: "secondary",
        itemColor: "secondary",
        outlined: true,
        dense: true
      },
      externalVisibility: false
    }),

    async created() {
      await this.fetchLocations({
        stringFilter: ""
      })
    },

    mounted() {
      this.$root.$on('fetch:data', async (item) => {
        this.editedIndex = item.id || -1;
        await this.fetchDocument(item)
      })
      this.$root.$on('clear:form', () => {
        this.clear()
      })
    },

    watch: {
      useSeason: {
        handler(newValue) {
          console.log('useSeason changed:', newValue);
        },
        deep: true
      }
    },

    computed: {
      ...mapGetters({
        lengthRules: "general/lengthRules",
        limitEnterKeyPress: "general/limitEnterKeyPress",
        requireRules: "general/requireRules",
        selectRule: "general/selectRule",
        requireRulesAndSize: "general/requireRulesAndSize",
        storageDeviceTypesActiveByCompany:
          "storage_type/storageDeviceTypesActiveByCompany",
        storages: "storage/storagesList",
        locations: "locations/locationsList",
        documentAuthorities: 'document_authority/documentAuthorities',
        documentTypes: 'document_type/documentTypes',
        seasons: 'season/seasons',
        languageStructure: "general/languageStructure",
      }),
      documentDescriptions: {
        get() {
          return this.$store.getters[
            "document_description/documentDescriptions"
          ]
        },
        set(val) {
          this.$store.commit(
            "document_description/SET_DOCUMENT_DESCRIPTION_LIST",
            val
          )
        },
      },
      documentSelected: {
        get() {
          return this.$store.getters["documents/document"]
        },
        set(val) {
          this.$store.commit("documents/SET_DOCUMENT", val)
        },
      },
      filters: {
        get() {
          return this.$store.getters["ui/GET_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_FILTERS", val)
        },
      },

      computedDocumentDate: {
        get() {
          return this.editedIndex === -1
            ? this.documentSelected.document_date
              ? new Date(this.documentSelected.document_date)
              : null
            : this.documentSelected.document_date
            ? new Date(this.documentSelected.document_date)
            : null
        },
        set(data) {
          if (data) this.documentSelected.document_date = new Date(data)
          else this.documentSelected.document_date = null
        },
      },
      computedExpirationDate: {
        get() {
          return this.editedIndex === -1
            ? this.documentSelected.expiration_date
              ? new Date(this.documentSelected.expiration_date)
              : null
            : this.documentSelected.expiration_date
            ? new Date(this.documentSelected.expiration_date)
            : null
        },
        set(data) {
          if (data) this.documentSelected.expiration_date = new Date(data)
          else this.documentSelected.expiration_date = null
        },
      },
      formTitle() {
        return this.editedIndex === -1
          ? i18n.t("documents.gestion.add_title")
          : i18n.t("documents.gestion.edit_title")
      },
      selectedlengthStorages() {
        return this.storagesSelected && this.storagesSelected.length > 0
      },
    },

    methods: {
      ...mapActions({
        fetchListDocumentsFiltered: "documents/fetchListDocumentsFiltered",
        fetchListDocumentDescriptionsByType:
          "document_description/fetchListDocumentDescriptionsByType",
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages",
        saveDocument: 'documents/saveDocument',
        fetchDocumentById: 'documents/fetchDocumentById',
        fetchListSeasons: 'season/fetchListSeasons',
      }),
      openDialog() {
        this.editedIndex = -1;
        this.$emit('dialog:action', true);
      },
      async searchSeason(event) {
        this.loadings.seasons = true
        const filters = {
          companyId: this.profile.company_id,
          search: event || "",
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'DESC',
          }
        }

        await this.fetchListSeasons([filters, this.$toast]).finally(() => {
          this.loadings.seasons = false
        })
      },
      orderData(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      async fetchDocument(item) {
        this.clear();

        this.loadings.init = true
        this.$emit('dialog:action', true)

        if (item) {
          await this.fetchDocumentById([item.id, this.$toast]).then(async () => {
            let storageDeviceType = this.storageDeviceTypesActiveByCompany.find(
              x =>
                x.storage_device_type_id ===
                this.documentSelected.storageDeviceType.id
            )
            this.$set(
              this.documentSelected,
              'storageDeviceType',
              storageDeviceType
            )

            await this.fetchPrimaryLocations([{
              versionIds: [this.documentSelected.primaryLocation.id],
              onlyCurrentVersion: false
            }, this.$toast])

            await this.fetchStorageLocations([{
              primaryLocationVersionIds: [this.documentSelected.primaryLocation.id],
              versionIds: this.documentSelected.storage_device_ids,
              onlyCurrentVersion: false
            }, this.$toast]).then(() => {
              this.$set(this, 'storagesSelected', _.clone(this.storages))
            });
          })

          if (this.documentSelected.season)
            await this.searchSeason(this.translate(this.documentSelected.season.languageKey))

          this.useSeason = this.documentSelected.useSeason;

          if (this.documentSelected.season) {
            this.season = {
              ...this.documentSelected.season,
              season_name: this.translate(this.documentSelected.season.languageKey)
            }
          }

          this.externalVisibility = this.documentSelected.externalVisibility;

          // this.observations_text = this.translate(this.documentSelected.observations)

          await this.fetchListDocumentDescriptionsByType([
            this.profile.company_id,
            [this.documentSelected.documentType.id],
            this.$toast,
          ])

          this.loadings.document = true
          // download zip document to show
          await DocumentsService.downloadDocument(this.documentSelected.id)
            .then(response => {
              let type = MIME_TYPE[this.documentSelected.file_extension]

              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              const jsZip = require('jszip')
              jsZip.loadAsync(blob).then(zip => {
                Object.keys(zip.files).forEach(filename => {
                  zip.files[filename].async('blob').then(fileData => {
                    this.fileSelected = new File([fileData], filename, {
                      type: type,
                    })
                  })
                })
              })
            })
            .catch(e => {
              this.$toast.success(
                i18n.t('documents.notification.evidencesCero'),
                {
                  queueable: true,
                }
              )
            })
            .finally(() => {
              this.loadings.document = false
              this.loadings.init = false
            })
        }
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      clearDocumentType() {
        this.documentSelected.documentType = null
        this.documentDescriptionFiltered()
      },
      async changeSDT(filters) {
        await this.fetchLocations(filters)
        this.storagesSelected = []
        this.documentSelected.primaryLocation = []
        this.storagesSelected = []
      },
      async fetchLocations(filters) {
        if (this.documentSelected.storageDeviceType) {
          if (filters && filters.storageDeviceTypeIds) {
            this.$set(this.documentSelected, "primaryLocation", null)
            this.$set(this, 'storagesSelected', [])

            this.$set(this.loadings, "origin", true)

            if (typeof filters.storageDeviceTypeIds === 'object')
              filters.storageDeviceTypeIds = [filters.storageDeviceTypeIds.storage_device_type_id]

            await this.fetchPrimaryLocations([
              filters,
              this.$toast,
            ]).finally(() => {
              this.$set(this.loadings, "origin", false)
            })
          }
        }
      },
      async fetchStorages(filters) {
        this.storagesSelected = [];
        if (this.documentSelected.storageDeviceType) {
          if ((filters.primaryLocationVersionIds && filters.primaryLocationVersionIds.length > 0 && filters.primaryLocationVersionIds[0] !== null)) {
            this.$set(this.loadings, 'destination', true)

            if (typeof filters.primaryLocationVersionIds === 'object') {
              _.forEach(filters.primaryLocationVersionIds, (l, k) => {
                if (l)
                  filters.primaryLocationVersionIds[k] = l.id
              })
            }

            for (let property in filters) {
              if (filters[property] === null) {
                delete filters[property]
              }
            }

            await this.fetchStorageLocations([
              filters,
              this.$toast,
            ]).finally(() => {
              this.$set(this.loadings, 'destination', false)
            })
          }
        }
      },
      async documentDescriptionFiltered(type) {
        if (type) {
          this.loadings.description = true
          await this.fetchListDocumentDescriptionsByType([
            this.profile.company_id,
            [type.id],
            this.$toast,
          ]).finally(() => {
            this.loadings.description = false
          })
        } else {
          this.loadings.description = null
        }
      },
      async onChangeFile() {
        const extension = this.fileSelected
          ? this.fileSelected.name.split(".")[1]
          : null

        let type = MIME_TYPE[extension]

        if (["jpg", "jpeg", "png"].includes(extension)) {
          await CompactImages.downscaleImage(0.5, this.fileSelected, result => {
            if (result.size <= 5000000) {
              this.fileSelected = new File(
                [result],
                result.name,
                {
                  type: type,
                }
              )
            }
          })
        }

        this.fileEditChange = this.editedIndex > -1
      },
      async save() {
        this.loadings.save = true
        let document = Object.assign({}, this.documentSelected)
        document.company = { id: this.profile.company_id }
        document.name = this.fileSelected.name.split(".")[0]
        document.file_extension = this.fileSelected.name.split(".")[1]
        const currentTime = moment()

        if (this.season)
          document.seasonId = this.season.id;
        else
          document.seasonId = null;

        document.useSeason = this.useSeason

        document.document_date = (
          moment(
            this.documentSelected.document_date
              ? this.documentSelected.document_date
              : new Date()
          )
            .format()
            .slice(0, 10) +
          "T" +
          moment(currentTime).format("HH:mm:ss")
        ).slice(0, 19)

        if (this.documentSelected.expiration_date) {
          document.expiration_date = (
            moment(this.documentSelected.expiration_date)
              .format()
              .slice(0, 10) +
            "T" +
            moment(currentTime).format("HH:mm:ss")
          ).slice(0, 19)
        } else {
          this.documentSelected.expiration_date = null
        }

        document.documentAuthority = {
          id: this.documentSelected.documentAuthority.id,
        }
        document.documentDescription = {
          id: this.documentSelected.documentDescription.id,
        }
        document.documentType = { id: this.documentSelected.documentType.id }

        if (this.storagesSelected.length > 0 && typeof this.storagesSelected[0] === "object")
          this.storagesSelected = _.map(this.storagesSelected, (s) => s.id)

        this.$set(document, "storageDeviceIds", this.storagesSelected)

        this.$set(document, "externalVisibility", this.externalVisibility)

        /*document.observations = LanguageService.setKey3(
            this.observations_text,
            document.observations,
            !document.id ? this.languageStructure : null
        );*/

        await this.saveDocument([
          document,
          this.fileSelected,
          this.storagesSelected,
          this.editedIndex,
          this.fileEditChange,
          this.renew,
          this.$toast,
        ]).finally(() => {
          this.$emit('update:loading', true)
          this.fetchListDocumentsFiltered([{
            ...this.filters,
            pageableDTO: {
              page: 0,
              size: 10,
              sortBy: 'id',
              direction: 'DESC',
            }
          }, this.$toast]).finally(() => {
            this.$emit('update:loading', false)
          })
        })

        this.loadings.save = false
        this.close()
      },
      clear() {
        if (this.$refs.form) {
          this.$refs.form.reset()
          this.$store.commit("documents/SET_ORIGINAL_STATE_DOCUMENT")
          this.fileSelected = null
          this.editeIndex = -1
          this.$refs.expiration_dateRef.clearDate()
          this.$refs.document_dateRef.clearDate()
        }
      },
      close() {
        this.clear()
        this.$emit('dialog:action', false)
      },
      check(permisions) {
        return PermisionService.check(permisions)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
        this.search = event.currentTarget.value.toString().replaceAll('"', "")
      },
      replaceDescriptionText(event) {
        this.documentSelected.observations = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },
    destroyed() {
      this.$root.$off('fetch:data')
      this.$root.$off('clear:form')
    }
  }
</script>

<style scoped>
:deep(.v-input__append-outer span .v-input) {
  margin: 0
}
</style>
